import { useEffect, useState } from 'react';
import type { ApolloError } from 'apollo-client';

import {
	useGetOnboardingState,
	useOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import {
	QUICKSTART_STATE_KEYS,
	TASK_ID_TO_STATE_KEY,
} from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { fg } from '@confluence/feature-gating';

export const useOnboardingCTANudge = ({ actionSubjectId }: { actionSubjectId: string }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [errors, setErrors] = useState<(ApolloError | Error)[]>([]);

	// ------ Quickstart Nudge Context ------
	const {
		data,
		loading: quickstartStateLoading,
		error: quickstartStateError,
	} = useGetOnboardingState(Object.values(QUICKSTART_STATE_KEYS));
	const { setOnboardingState } = useOnboardingState();
	const deserializedState = deserializeState(data);
	const { openTaskId, openComponentId }: { openTaskId: string; openComponentId: string } =
		deserializedState;
	const quickstartTask = TASK_ID_TO_STATE_KEY[openTaskId];

	// ------ Experiments ----------
	const CTANudgeConditions = openComponentId === actionSubjectId; // QS Button to show nudge is clicked

	const experimentEligible = CTANudgeConditions && fg('cc_onboarding_experience');

	const setHidden = () => {
		if (!deserializedState[quickstartTask]) {
			void setOnboardingState({
				key: quickstartTask,
				value: 'true',
			});
		}
		// Under very rare occasion, onShowProp below run out of order, or not at all.
		// The visibility is controlled by both isVisible and the openComponentId
		// Therefore, ensure that openComponentId is set to none if somehow its still set
		// to the current action
		if (CTANudgeConditions) {
			void setOnboardingState({
				key: QUICKSTART_STATE_KEYS.OPEN_COMPONENT_ID,
				value: 'none',
			});
		}
		setIsVisible(false);
	};

	useEffect(() => {
		if (experimentEligible) {
			setIsVisible(true);
		}
	}, [experimentEligible]);

	useEffect(() => {
		if (!quickstartStateLoading && quickstartStateError) {
			setErrors((prevErrors) => {
				return [...prevErrors, quickstartStateError];
			});
		}
	}, [quickstartStateLoading, quickstartStateError]);

	const onShow = () => {
		if (openComponentId !== 'none') {
			void setOnboardingState({
				key: QUICKSTART_STATE_KEYS.OPEN_COMPONENT_ID,
				value: 'none',
			});
		}
	};

	const onboardingProps = {
		setHidden,
		onShow,
		onboardingErrors: errors,
	};

	return { isVisible, onboardingProps };
};
